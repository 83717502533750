import { Link, NavLink } from "react-router-dom"
import "./Header.css"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux";
import { usersActions } from "../../store/user";

const Header = () => {
    function deleteCookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    const role = useSelector((state) => state.user.role)
    const dispatch = useDispatch();
    const logoutHandler = () => {
        dispatch(usersActions.changeIsLoggedIn(false))
        deleteCookie("session");
        window.location.href = "/";
    }

    return (
        <header className="header-container">
            <div className="header-content">
                <Link to="/"><img src="/static/images/logo.svg" alt="Logo" className="header-logo" /></Link>
                <nav>
                    <ul className="nav-bar">
                        <li><NavLink to="/">Users</NavLink></li>
                        <li><NavLink to="/logs">User Logs</NavLink></li>
                        {role === "sadmin" && <li><NavLink to="/accounts">Auction Accounts</NavLink></li>}
                        {role === "sadmin" && <li><NavLink to="/admins">Admins</NavLink></li>}
                    </ul>
                </nav>
                <button onClick={logoutHandler} className="logout-button">Log Out</button>
            </div>
        </header>
    )
}

export default Header