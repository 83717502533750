import "./policy.css"
const Privacy = () => {
    return (
        <div className="policy">
            <h1 classNameName="HeaderTitle" style={{textAlign: "center", marginTop: "20px"}}>Privacy Policy</h1>
            <section className="content">
                <h2 className="sectionTitle">Overview</h2>
                <div className="sect">
                    <p className="text">Auctions Softand its affiliates and subsidiaries (hereinafter, auctionssoft.com”, “we", "us", or
                        "our") take privacy and security seriously and we make all efforts to use your personal information
                        properly. We have put together this Privacy Policy to set out how we treat your personal information.</p>
                    <p className="text">This Privacy Policy governs each website, mobile site, application, and/or other service,
                        regardless of how distributed, transmitted, published, or broadcast (each, a "Site") provided by Auctions Soft,
                        its partners, affiliates and/or its subsidiaries that links to this Privacy Policy, which is binding on all
                        those who access, visit and/or use the Site (collectively, "you" or "your").</p>
                    <p className="text">This Privacy Policy describes the information collected as part of the normal operation of the
                        Site, process of collection of the information from you and what may happen to that information. Although
                        this policy may seem long, we have prepared a detailed policy because we believe you should know as much as
                        possible about auctionssoft.com’s practices so that you can make informed decisions.</p>
                    <p className="text">By accepting the Privacy Policy and the Terms of Use Agreement, you expressly consent to our use
                        and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is
                        deemed incorporated into and subject to the terms of Auctions SoftTerms of Use Agreement.</p>
                    <p className="text">Our primary purpose in collecting personal information is to provide you with a safe, smooth,
                        efficient, and customized experience. This allows us to provide services and features that are most likely
                        to meet your needs, and to customize our service to make your experience safer and easier. We only collect
                        personal information about you that we consider necessary for achieving this purpose.</p>
                </div>
                <h2 className="margin sectionTitle">1. Information We Collect From You</h2>
                <div className="sect">
                    <p className="text">A. General Account Registration - When you register for an account on Auctions Softin order to
                        purchase a vehicle or access any of our services, we ask for certain information. As part of the account
                        registration process we ask for your name, phone number and email address. In addition, when creating the
                        account, you will also be required to choose a password for accessing the created account. We store the
                        information you have provided in our database.</p>
                    <p className="text">B. Registration for Events and Forms - We record your name, email, phone number and, if required
                        to be provided, address that you submit when you (i) register for events, including webinars or seminars,
                        (ii) subscribe to our newsletters or any other mailing list, (iii) provide your information in connection
                        with coupons and promotions that we are running, (iv) participate in contests or respond to surveys, (v)
                        submit a form to request one of our customer support representatives to contact you, (vi) speak to our
                        customer support representative through live chat or (vii) when you contact Auctions Softfor any other
                        purpose.</p>
                    <p className="text">C. Payment Processing I - Deposit - When you make a deposit with Auctions Softfor the purpose of
                        purchasing a vehicle, we ask you to provide your name, contact information (including your address), and
                        credit card information or other payment account information. In connection with deposits made with
                        Auctions Soft, when you submit your credit card information, we store the name and address of the cardholder,
                        the expiry date, the type of credit card (i.e. Visa, MasterCard, etc.…) and the last four digits of the
                        credit card number. We do not store the actual credit card number. We wanted to also let you know that when
                        you place a deposit payment on the Site, we: i) may request a copy of your driver’s license (or other form
                        of government issued identification) and your credit card (covering the credit card number except for the
                        last 4 digits) and ii) obtain your IP address. This information is important to safeguard against fraudulent
                        transactions by ensuring that the owner of the credit card is the one who is placing the deposit. Merchant
                        banks and payment service providers will also receive this information in order to process the deposit. As
                        mentioned above, we take privacy and security seriously and will not share this information with third
                        parties other than with the merchant banks and payment service providers who are processing the transaction.
                    </p>
                    <p className="text">D. Payment Processing II – Purchase of Vehicle - When you purchase a vehicle from Auctions Soft, we
                        collect the following information from you: i) your name, ii) contact information (including your address),
                        and iii) government issued identification. As a licensed car dealer, we need to collect this information in
                        order to complete and provide you with the bill of sale and vehicle title documentation.</p>
                    <p className="text">E. Placing a Bid, Making an Offer, Using the Buy Now - If you choose to make an offer, place a
                        bid or buy a vehicle on our Site, we collect information about your offer, bids and buying behavior. That
                        information can and will be used by our subsidiaries, affiliates and business partners.</p>
                    <p className="text">F. Other Interaction with Auctions Soft– Information included in any submissions on our ‘Contact
                        Us’ page or other form where you try to contact Auctions Softin connection with general or specific enquiries
                        or support. We may also record, analyze and use your interactions with us, including email, telephone, and
                        chat conversations with our sales and customer support professionals, for improving our interactions with
                        you and other customers.</p>
                </div>
                <h2 className="margin sectionTitle">2. Information We Collect Automatically From You</h2>
                <div className="sect">
                    <p className="text">A. Information from Browsers, Devices and Servers - When you visit our Site, whether as a
                        registered or non-registered user, our servers collect information that web browsers, mobile devices and
                        servers make available, such as your computer’s Internet Protocol, IP address, browser type, browser
                        software version, language preference, time zone, referring URL (i.e. the webpage you were on before coming
                        to our Site), date and time of access, the time spent on those pages and on our Site, operating system,
                        mobile device manufacturer and mobile network information and other such related statistical information. We
                        include these in our log files to understand more about visitors to our Sites. In addition, we may use third
                        part services that collect, monitor and analyze this type of information with the purpose of increasing our
                        Site’s functionality and user friendliness, to better tailor it to your needs, and in some cases to detect
                        suspicious activity. These third-party service providers have their own privacy policies addressing how they
                        use such information.</p>
                    <p className="text">B. Information from Application Logs and Mobile Analytics - We collect information about your
                        use of our products, services and, where applicable, mobile applications from application logs and in-house
                        and third-party usage analytics tools and use it to understand how your business use and needs can improve
                        our products. This information includes clicks, scrolls, features accessed, access time and frequency,
                        errors generated, performance data, storage utilized, user settings and configurations, and devices used to
                        access and their locations.</p>
                </div>
                <h2 className="margin sectionTitle">3. Information We Collect From Third Parties</h2>
                <div className="sect">
                    <p className="text">A. Login using Federated Authentication Service Providers - You can log in to Auctions Softusing
                        supported federated authentication service providers such as LinkedIn, Facebook, Twitter and Google. These
                        services will authenticate your identity and give you the option to share certain personal information with
                        us, such as your name and email address.</p>
                    <p className="text">B. Referrals - If someone has referred any of our products or services to you through any of our
                        referral programs, that person may have provided us your name, email address and other personal information.
                        You may contact us at info@auctionssoft.com to request that we remove your information from our database. If
                        you provide us information about another person, or if another person gives us your information, we will
                        only use that information for the specific reason for which it was provided to us.</p>
                    <p className="text">C. Information from Social Media Sites and other Publicly Available Sources - When you interact
                        or engage with us on social media sites such as Facebook, Twitter, Google+ and Instagram through posts,
                        comments, questions and other interactions, we may collect such publicly available information, including
                        profile information, to allow us to connect with you, improve our products, or better understand user
                        reactions and issues. Once this information is collected, it may remain with us even if you delete it from
                        the social media sites. Auctions Softmay also add and update information about you, from other publicly
                        available sources.</p>
                </div>
                <h2 className="margin sectionTitle">4. Purposes for Using Your Information</h2>
                <div className="sect">
                    <p className="text">We mention various purposes for using your information above. In addition to those purposes, we
                        may use your information for the following purposes:</p>
                    <ul>
                        <li>To communicate with you (such as through email) about our products and services that you have signed up
                            for, changes to this Privacy Policy, changes to the Terms of Use or other important notices;</li>
                        <li>To communicate with you about the offer, promotion and/or coupon that you have signed up for and have
                            utilized or still need to utilize, such as follow-up emails to remind you to utilize such offer,
                            promotion and/or coupon before it expires.</li>
                        <li>To keep you posted on new products and services, upcoming events, offers, promotions and other
                            information that we think will be of interest to you to the extent you have given us your prior consent,
                            or it is otherwise permitted under applicable law;</li>
                        <li>To ask you to participate in surveys, or to solicit feedback on our products and services;</li>
                        <li>To understand how users use our products and services, to monitor and prevent problems, and to improve
                            our products and services;</li>
                        <li>To provide customer support, troubleshoot issues, administer your inquiries and to analyze and improve
                            our interactions with customers;</li>
                        <li>To detect and prevent fraudulent transactions and other illegal activities, to report spam, and to
                            protect the rights and interests of Auctions Soft, Auctions Soft’s users, third parties and the public;</li>
                        <li>We use third parties that we refer to as internal service providers to facilitate or outsource one or
                            more aspects of our business, product and service operations that we provide to you (e.g., live chat,
                            customer relationship management, search technology, bill collection, affiliate and rewards programs)
                            and therefore we may provide some of your personal information directly to these internal service
                            providers;</li>
                        <li>To update, expand and analyze our records, identify new customers, and provide products and services
                            that may be of interest to you;</li>
                        <li>To analyze trends, administer our websites, and track visitor navigations on our websites to understand
                            what visitors are looking for and to better help them;</li>
                        <li>To identify problems with your account and resolve any disputes;</li>
                        <li>To enforce our Terms of Use and to collect any fees or charges owned to us; and</li>
                        <li>To monitor and improve marketing campaigns and make suggestions relevant to the user.</li>
                    </ul>
                </div>
                <h2 className="margin sectionTitle">5. Legal Basis for Collecting and Using Your Information</h2>
                <div className="sect">
                    <p className="text">If you are an individual from the European Economic Area (EEA), our legal basis for information
                        collection and use depends on the personal information concerned and the context in which we collect it.
                        Most of our information collection and processing activities are typically based on (i) contractual
                        necessity, (ii) one or more legitimate interests of Auctions Softor a third party that are not overridden by
                        your data protection interests, or (iii) your consent. Sometimes, we may be legally required to collect your
                        information, or may need your personal information to protect your vital interests or those of another
                        person.</p>
                    <p className="text">Where we rely on your consent as the legal basis, you have the right to withdraw your consent at
                        any time, but this will not affect any processing that has already taken place.</p>
                    <p className="text">Where we rely on legitimate interests as the legal basis and those legitimate interests are not
                        specified above, we will clearly explain to you what those legitimate interests are at the time that we
                        collect your information.</p>
                </div>
                <h2 className="margin sectionTitle">6. Information and Choice as to its Use</h2>
                <div className="sect">
                    <p className="text">You may opt out of receiving newsletters and other non-essential messages by using the
                        ‘unsubscribe’ function included in all such messages. However, you will continue to receive notices and
                        essential transactional emails.</p>
                    <p className="text">You can choose not to provide optional profile information. You can also delete or change your
                        optional profile information. You can always choose not to fill in non-mandatory fields when you submit any
                        form linked to our websites.</p>
                </div>
                <h2 className="margin sectionTitle">7. Sharing of Your Information</h2>
                <div className="sect">
                    <p className="text">Auctions Softand its affiliates and subsidiaries have access to the information covered in
                        sections 1, 2 and 3. We do not sell, rent nor lease any of your personal information. We share your
                        information only in the ways that are described in this Privacy Policy, and only with parties who adopt
                        appropriate confidentiality and security measures.</p>
                    <p className="text">Employees and independent contractors of all Auctions Softgroup entities have access to the
                        information covered in sections 1, 2 and 3 on a need-to-know basis. We require all employees and independent
                        contractors of Auctions Soft, its affiliates and subsidiaries to follow this Privacy Policy for personal
                        information that we share with them.</p>
                    <p className="text">We may need to share your personal information and aggregated or de-identified information with
                        third party service providers that we engage, such as marketing and advertising partners, shipping providers
                        and companies (as mentioned above), live chat and customer relationship management application providers,
                        event organizers, web analytics providers, merchant banks and payment processors. These service providers
                        are authorized to use your personal information only as necessary to provide these services to us.</p>
                </div>
                <h2 className="margin sectionTitle">8. Retention of Information</h2>
                <div className="sect">
                    <p className="text">Auctions Softretains your personal information for as long as it is required for the purposes
                        stated in this Privacy Policy. Sometimes, we may retain your information for longer periods as permitted or
                        required by law, such as to maintain suppression lists, prevent abuse, if required in connection with a
                        legal claim or proceeding, to enforce our agreements, for tax, accounting, or to comply with other legal
                        obligations.</p>
                </div>
                <h2 className="margin sectionTitle">9. Disclosure of Information to Law Enforcement</h2>
                <div className="sect">
                    <p className="text">auctionssoft.com fully cooperates with law enforcement inquiries, as well as other third parties to
                        enforce laws, such as: intellectual property rights, fraud and other rights, to help protect you and the
                        auctionssoft.com from bad actors. Therefore, in response to lawful requests by law enforcement or other
                        government officials relating to a criminal investigation or alleged illegal activity, we can disclose your
                        name, city, state, telephone number, email address, User ID, fraud complaints, and offer and payment history
                        without a subpoena. Without limiting the above, in an effort to respect your privacy and our ability to keep
                        the community free from bad actors, we will not otherwise disclose your personal information to law
                        enforcement or other government officials without a subpoena, court order or substantially similar legal
                        procedure, except when we believe in good faith that the disclosure of information is necessary to prevent
                        imminent physical harm or financial loss; or report suspected illegal activity. </p>
                </div>
                <h2 className="margin sectionTitle">10. Enforcement of our Rights</h2>
                <div className="sect">
                    <p className="text">Due to the existing regulatory environment, we cannot ensure that all of your private
                        communications and other personal information will never be disclosed in ways not otherwise described in
                        this Privacy Policy. By way of example (without limiting the foregoing), we may be forced to disclose
                        personal information to the government or third parties under certain circumstances such as preventing
                        fraud, investigating any suspected illegal activity, enforcing our agreements or policies or protecting the
                        safety of our users.</p>
                </div>
                <h2 className="margin sectionTitle">11. External Links on Our Site</h2>
                <div className="sect">
                    <p className="text">Some pages of our websites may contain links to websites that are not linked to this Privacy
                        Policy. If you submit your personal information to any of these third-party sites, your personal information
                        is governed by their privacy policies. As a safety measure, we recommend that you not share any personal
                        information with these third parties unless you've checked their privacy policies and assured yourself of
                        their privacy practices.</p>
                </div>
                <h2 className="margin sectionTitle">12. Compliance</h2>
                <div className="sect">
                    <p className="text">We make every effort to ensure that personal information you provide is used in conformity with
                        this Privacy Policy. If you have any concerns about our adherence to this Privacy Policy or the manner in
                        which your personal information is used, kindly write to us info@auctionssoft.com.</p>
                </div>
                <h2 className="margin sectionTitle">13. Changes to this Privacy Policy</h2>
                <div className="sect">
                    <p className="text">From time to time, we may update this policy. If the updates have minor consequences, they will
                        take effect immediately when we post the revised Privacy Policy on our Site. If there are substantial
                        changes to the Privacy Policy, it will be effective 30 days after we initially posted the Privacy Policy on
                        our Site and sent you an update email.</p>
                    <p className="text">Until the new policy takes effect, if it materially reduces the protection of your privacy right
                        under the then-existing policy you can choose not to accept it and terminate your use of our Site and
                        services and stop purchasing products from our Site. Continuing to use our Site, purchase our products and
                        user our services after the new policy takes effect means that you agree to the new policy. Note that if we
                        need to adapt the policy to legal requirements, the new policy will become effective immediately or as
                        required by law.</p>
                </div>
                <h2 className="margin sectionTitle">14. Incorporation to the Terms of Use</h2>
                <div className="sect">
                    <p className="text marginbtn">This policy is an integral part of our Terms of Use and, as applicable, any other
                        agreement entered into between you (or the entity that you are acting on its behalf) and us. Therefore, this
                        Privacy Policy is hereby incorporated into the Terms of Use and any such agreement.</p>
                </div>
            </section>
        </div>
    )
}

export default Privacy