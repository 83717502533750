import axios from "axios";
import { useEffect, useState } from "react"
import yesIcon from "../../icons/check.png"
import noIcon from "../../icons/cross.png"
import DataTable from "../../Common/DataTable/DataTable";
import AsideModal from "../../Common/AsideModal/AsideModal";
import EditAdmin from "./EditAdmin";
import { Modal, Button } from "react-bootstrap";

const adminHeader = [{ id: 50, name: "Admin name", sortable: true, flex: 1, propName: "username", isActive: false }, {
    id: 63, name: "City", sortable: true, flex: 1, propName: "city", isActive: false
}, {
    id: 53, name: "Copart account", sortable: true, flex: 1, propName: "copartAccount", isActive: false
}, { id: 54, name: "IAAI account", sortable: true, flex: 1, propName: "iaaiAccount", isActive: false }, {
    id: 55, name: "Phone number", sortable: true, flex: 1, propName: "phone", isActive: false
}, {
    id: 51, name: "Email", sortable: true, flex: 1, propName: "email", isActive: false
}, { id: 52, name: "Role", sortable: true, flex: 1, propName: "usersCount", isActive: false }, {
    id: 83, name: "Actions", sortable: false, flex: 0.5, propName: "none", isActive: false
}]

const Admin = () => {
    const [data, setData] = useState([]);
    const [active, setActive] = useState({});
    const [keyWord, setKeyWord] = useState("")
    const [actionType, setActionType] = useState('edit');
    const [showAsideModal, setShowAsideModal] = useState(false);
    const [showDeleteAdminModal, setShowDeleteAdminModal] = useState(false);
    const [adminForm, setAdminForm] = useState({
        username: "",
        name: "",
        surname: "",
        country: "",
        city: "",
        copartAccount: "",
        iaaiAccount: "",
        phone: "",
        email: "",
        role: "admin",
        password: "",
        status: ""
    })

    useEffect(() => {
        axios(`/api/admins`)
            .then((response) => {
                const admins = response.data.data;

                setData([...admins])
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    const handlers = {
        editAdmin(admin) {
            setShowAsideModal(true);
            setActionType("edit");
            setAdminForm({
                username: admin.username,
                name: admin.name,
                surname: admin.surname,
                country: admin.country,
                city: admin.city,
                copartAccount: admin.copartAccount,
                iaaiAccount: admin.iaaiAccount,
                phone: admin.phone,
                email: admin.email,
                role: "admin",
                status: admin.status,
                password: admin.password
            });
            setActive({ ...admin })
        },
        addAdmin() {
            setShowAsideModal(true);
            setActionType("add")
            setAdminForm({
                username: "",
                name: "",
                surname: "",
                country: "",
                city: "",
                copartAccount: "",
                iaaiAccount: "",
                phone: "",
                email: "",
                role: "admin",
                status: ""
            })
        },
        deleteAdmin(admin) {
            setShowDeleteAdminModal(true);
            setActive({ ...admin })
        },
        saveAdminData() {
            axios.put(`/api/admin/${active.id}`, adminForm)
                .then((response) => {
                    const edit = response.data.data;
                    const updateAdmin = data.map((a) => {
                        if (active.id === a.id) {
                            return { ...edit, usersCount: active.usersCount }
                        } else {
                            return a
                        }
                    })

                    setData([...updateAdmin]);
                    setShowAsideModal(false);
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        createNewAdmin() {
            axios.post(`/api/admin`, adminForm)
                .then((response) => {
                    const admin = response.data;
                    setData((prev) => {
                        return [admin, ...prev]
                    })
                    setShowAsideModal(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        delete() {
            axios.delete(`/api/admin/${active.id}`)
                .then(() => {
                    const renewUsers = data.filter((a) => a.id !== active.id);
                    setData(renewUsers);
                    setShowDeleteAdminModal(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (<div className="users-table">
        <div className="filters-container">
            <input type="text" className="search-form" style={{width: "380px"}} onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    setKeyWord(e.target.value);
                }
            }} placeholder="Enter keyword" />
        </div>
        <div className="main-actions"><button className="action-main-buttons" onClick={handlers.addAdmin}>Add new</button></div>
        <Modal show={showDeleteAdminModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <div className="modal-title h4">Delete admin</div>
            </Modal.Header>
            <Modal.Body>
                <p> Are you sure you want to proceed?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-default" onClick={() => {
                    setShowDeleteAdminModal(false);
                }}> Cancel
                </button>
                <Button variant="info" onClick={handlers.delete}>Delete</Button>
            </Modal.Footer>
        </Modal>
        {
            showAsideModal && <AsideModal visible={showAsideModal} size={"400px"} title="Edit admin" setVisible={setShowAsideModal}><EditAdmin data={adminForm} dataSetter={setAdminForm} action={actionType} onSave={handlers.saveAdminData} onAdd={handlers.createNewAdmin} /></AsideModal>
        }
        <DataTable data={data} showSort={true} header={adminHeader} searchKeyword={keyWord} editAction={handlers.editAdmin} deleteAction={handlers.deleteAdmin} yesIcon={yesIcon} noIcon={noIcon} actions={true} dataSetter={setData} hiddenCol={["id", "name", "surname", "usersCount", "country", "password", "status"]} />
    </div>
    )
}

export default Admin