import { useState } from 'react'
import './Landing.css'
import axios from 'axios'
import { Button, Modal } from 'react-bootstrap'

const Landing = () => {
    const [showSuccess,setShowSuccess] = useState(false);
    const [formData, setFormData] = useState({
        username: "",
        name: "",
        surname: "",
        country: "",
        city: "",
        email: "",
        phone: "",
        password: "",
        repeatPassword: ""
    })

    const [error, setErrors] = useState({
        username: false,
        name: false,
        surname: false,
        country: false,
        city: false,
        email: false,
        phone: false,
        password: false,
        repeatPassword: false,
        passwordsDontMatch: false
    })

    const submitFormHandler = (e) => {
        if (formData.username === "") {
            setErrors((prev) => ({ ...prev, username: true }));
            return e
        };
        if (formData.name === "") {
            setErrors((prev) => ({ ...prev, name: true }));
            return e
        };
        if (formData.surname === "") {
            setErrors((prev) => ({ ...prev, surname: true }));
            return e
        };
        if (formData.country === "") {
            setErrors((prev) => ({ ...prev, country: true }));
            return e
        };
        if (formData.city === "") {
            setErrors((prev) => ({ ...prev, city: true }));
            return e
        };
        if (formData.email === "") {
            setErrors((prev) => ({ ...prev, email: true }));
            return e
        };
        if (formData.phone === "") {
            setErrors((prev) => ({ ...prev, phone: true }));
            return e
        };
        if (formData.password === "") {
            setErrors((prev) => ({ ...prev, password: true }));
            return e
        };
        if (formData.repeatPassword === "") {
            setErrors((prev) => ({ ...prev, repeatPassword: true }));
            return e
        };
        if(formData.password !== formData.repeatPassword){
            setErrors((prev) => ({ ...prev, passwordsDontMatch: true }));
            return e
        }

        axios.post('/api/users/sign-up', formData)
            .then(() => {
                setShowSuccess(true);
                setFormData({
                    username: "",
                    name: "",
                    surname: "",
                    country: "",
                    city: "",
                    email: "",
                    phone: "",
                    password: "",
                    repeatPassword: ""
                })
                setErrors({
                    username: false,
                    name: false,
                    surname: false,
                    country: false,
                    city: false,
                    email: false,
                    phone: false,
                    password: false,
                    repeatPassword: false,
                    passwordsDontMatch: false
                })
            })
            .catch((err) => {

            })
    }
    return (
        <>
            <Modal show={showSuccess} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>Success</Modal.Header>
                <Modal.Body>Thank you for signing up! We will contact you back in the nearest future.</Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => setShowSuccess(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <div className="auth-content-container">
                <div className="auth-content-modal">
                    <div className="auth-images">
                        Auctions Soft
                    </div>
                    <div className="auth-gray-line"></div>
                    <h2 className='auth-title'>Sign Up</h2>
                    <div className="auth-form-container">
                        <div className='sign-up-form'>
                            <div>
                                <div className="form-group">
                                    <label className='form-label'>Username</label>
                                    <input className={`form-control ${(error.username) && 'form-control-error'}`} value={formData.username} type="text" onChange={(e) => setFormData((prev) => ({ ...prev, username: e.target.value }))} placeholder="Enter username" />
                                    {error.username && <span className="form-error">Username is required</span>}
                                </div>
                                <div className="form-group">
                                    <label className='form-label'>Name</label>
                                    <input className={`form-control ${(error.name) && 'form-control-error'}`} value={formData.name} type="text" onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))} placeholder="Enter Name" />
                                    {error.name && <span className="form-error">Name is required</span>}
                                </div>
                                <div className="form-group">
                                    <label className='form-label'>Surname</label>
                                    <input className={`form-control ${(error.surname) && 'form-control-error'}`} type="text" value={formData.surname} onChange={(e) => setFormData((prev) => ({ ...prev, surname: e.target.value }))} placeholder="Enter Surname" />
                                    {error.surname && <span className="form-error">Surname is required</span>}
                                </div>
                                <div className="form-group">
                                    <label className='form-label'>Country</label>
                                    <input className={`form-control ${(error.country) && 'form-control-error'}`} value={formData.country} type="text" onChange={(e) => setFormData((prev) => ({ ...prev, country: e.target.value }))} placeholder="Enter country" />
                                    {error.country && <span className="form-error">Country is required</span>}
                                </div>
                                <div className="form-group">
                                    <label className='form-label'>City</label>
                                    <input className={`form-control ${(error.city) && 'form-control-error'}`} value={formData.city} type="text" onChange={(e) => setFormData((prev) => ({ ...prev, city: e.target.value }))} placeholder="Enter city" />
                                    {error.city && <span className="form-error">City is required</span>}
                                </div>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label className='form-label'>Email</label>
                                    <input className={`form-control ${(error.email) && 'form-control-error'}`} value={formData.email} type="text" onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))} placeholder="Enter email" />
                                    {error.email && <span className="form-error">Email is required</span>}
                                </div>
                                <div className="form-group">
                                    <label className='form-label'>Phone number</label>
                                    <input className={`form-control ${(error.phone) && 'form-control-error'}`} value={formData.phone} type="text" onChange={(e) => setFormData((prev) => ({ ...prev, phone: e.target.value }))} placeholder="Enter phone number" />
                                    {error.phone && <span className="form-error">Phone number is required</span>}
                                </div>
                                <div className="form-group">
                                    <label className='form-label'>Password</label>
                                    <input className={`form-control ${(error.password) && 'form-control-error'}`} value={formData.password} type="password" onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))} placeholder="Enter password" />
                                    {error.password && <span className="form-error">Password is required</span>}
                                </div>
                                <div className="form-group">
                                    <label className='form-label'>Repeat password</label>
                                    <input className={`form-control ${(error.passwordsDontMatch || error.repeatPassword) && 'form-control-error'}`} value={formData.repeatPassword} type="password" onChange={(e) => setFormData((prev) => ({ ...prev, repeatPassword: e.target.value }))} placeholder="Repeat password" />
                                    {error.repeatPassword && <span className="form-error">Please repeat password</span>}
                                    {error.passwordsDontMatch && <span className="form-error">Passwords don't match </span>}
                                </div>
                            </div>
                        </div>
                        <div className="auth-button-container">
                            <button className="auth-submit-form-button" onClick={submitFormHandler}>Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Landing